import React, { Component } from "react";
import MathPage from "./MathPage.js";

export default class Math1 extends Component {
  render() {
    return (
      <MathPage
        nonUnitLinksTB="math1NonUnitLinks"
        unitLinksTB="math1UnitLinks"
        nonUnitTitlesTB="math1NonUnitTitles"
        unitsTB="math1Units"
        announcementsTB="math1Announcements"
        pageName="Math 1"
        gCalLink="https://calendar.google.com/calendar/embed?src=c_f3q5ouqi5f1dn8tav1i7kbnea8%40group.calendar.google.com&ctz=America%2FNew_York"
        gCalTitle="Math 1 Calendar"
      />
    );
  }
}

import React, { Component } from "react";
import MathPage from "./MathPage.js";

export default class Math2 extends Component {
  render() {
    return (
      <MathPage
        nonUnitLinksTB="math2NonUnitLinks"
        unitLinksTB="math2UnitLinks"
        nonUnitTitlesTB="math2NonUnitTitles"
        unitsTB="math2Units"
        announcementsTB="math2Announcements"
        pageName="Math 2"
        gCalLink="https://calendar.google.com/calendar/embed?src=c_dkambmmoi6mrt5e48bk55lvip0%40group.calendar.google.com&ctz=America%2FNew_York"
        gCalTitle="Math 2 Calendar"
      />
    );
  }
}

import React, { Component } from 'react';
//import MomPhoto from './mom.jpg';
import '../App.css';
import 'bulma/css/bulma.css';
import axios from 'axios';

export default class Home extends Component {
    constructor(props){
      super(props);

      this.state = {
        announcements: [],
      };
    }

    componentDidMount(){
      axios.post('http://mathwithpack.com/ZNytvCmJmathwithpackFetchTable.php',
      [{"password":"mreN82Esk276634nGhaksdTvksn88727492329828281"}, {"table":"homePageAnnouncements"}])
      .then(res => {
        let returnedAnnouncements = [];
        let data = res.data;

        data.sort((a,b) => a.ID - b.ID);

        for(let x=0;x < res.data.length; x++){
          returnedAnnouncements[x] = data[x];
        }

        this.setState({ announcements: returnedAnnouncements });
      });
    }
    render() {
      return (
        /*the code for the waves animation comes from a free to use CodePen created by
        Daniel Österman, https://codepen.io/goodkatz/pen/LYPGxQz*/
        <div class="header">
          <div class="inner-header flex">
            <h1 class="title" id="homePageh1ColorWhite">Mrs. Pack's Math Website</h1>
          </div>

          <div className="homeComponentAnnouncementDiv">
              {this.state.announcements.map(e => {
                return (
                  <p class="pWhite">{e.announcement}</p>
                );
              })}
          </div>

          <div>
            <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
            <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g class="parallax">
            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
            </svg>
          </div>

          <div class="content flex">
            <p class="pWhite">
              The wave animation comes from a free to use&nbsp;
              <a href='https://codepen.io/goodkatz/pen/LYPGxQz' target='_blank' 
                rel='noopener noreferrer' class="aWhite">
                CodePen created by Daniel Österman
              </a>
            </p>
          </div>
        </div>

          
        /*
        <div>
          <div className="homeComponent">
            <div className="momPhoto">
              <img src={MomPhoto} className="momChinaPhoto" alt="Mrs. Pack"/>
            </div>
            <div className="homeComponentTitleDiv">
              <h1 className="title">Mrs. Pack's Math Website</h1>
            </div>
          </div>
          <div className="homeComponentAnnouncementDiv">
            {this.state.announcements.map(e => {
              return (
                <p>{e.announcement}</p>
              );
            })}
          </div>
        </div>
        */
      );
    }
}
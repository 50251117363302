import React, { Component } from "react";
import MathPage from "./MathPage.js";

export default class Math3 extends Component {
  render() {
    return (
      <MathPage
        nonUnitLinksTB="afmNonUnitLinks"
        unitLinksTB="afmUnitLinks"
        nonUnitTitlesTB="afmNonUnitTitles"
        unitsTB="afmUnits"
        announcementsTB="afmAnnouncements"
        pageName="AFM"
        gCalLink="https://calendar.google.com/calendar/embed?src=c_qklg0qn4p5pa050ps06ni1apuo%40group.calendar.google.com&ctz=America%2FNew_York"
        gCalTitle="AFM Calendar"
      />
    );
  }
}

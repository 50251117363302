import React from 'react';
//import { BrowserRouter } from 'react-router-dom';
import { HashRouter } from 'react-router-dom';
import Dashboard from './components/Dashboard.js';

function App() {
  return (
    /*
    <BrowserRouter>
      <Dashboard />
    </BrowserRouter>*/
   <HashRouter>
     <Dashboard />
   </HashRouter>
  );
}

export default App;

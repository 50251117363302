import React, { Component } from 'react';
import axios from 'axios';
import '../App.css';
import 'bulma/css/bulma.css';

export default class MathPage extends Component {
    constructor(props){
      super(props);
    
      this.state = {
        unitTitles: [],
        unitLinks: [[],[],[],[],[],[],[],[],[],[],[],[]],
        nonUnitTitles: [],
        nonUnitLinks: [[],[],[],[],[],[],[],[],[],[],[],[]],
        announcements: []
      };
    }

    componentDidMount() {
        axios.post('http://mathwithpack.com/ZNytvCmJmathwithpackFetchTable.php',
        [{"password":"mreN82Esk276634nGhaksdTvksn88727492329828281"}, {"table":this.props.nonUnitLinksTB}])
        .then(res => {
          let data = res.data;
          data.sort((a,b) => a.sectionID - b.sectionID);

          let returnedNonUnitLinks = [[],[],[],[],[],[],[],[],[],[],[],[]];
          for(let x=0;x<data.length;x++){
            let specificLinks = returnedNonUnitLinks[parseInt(data[x].sectionID)-1];
            specificLinks.push(data[x]);
          }

          this.setState({ nonUnitLinks: returnedNonUnitLinks });
        });

        axios.post('http://mathwithpack.com/ZNytvCmJmathwithpackFetchTable.php',
        [{"password":"mreN82Esk276634nGhaksdTvksn88727492329828281"}, {"table":this.props.unitLinksTB}])
        .then(res => {
          let data = res.data;
          data.sort((a,b) => a.unitNumber - b.unitNumber);

          let returnedUnitLinks = [[],[],[],[],[],[],[],[],[],[],[],[]];
          for(let x=0;x<data.length;x++){
            let specificLinks = returnedUnitLinks[parseInt(data[x].unitNumber)-1];
            specificLinks.push(data[x]);
          }

          this.setState({ unitLinks: returnedUnitLinks });
        });

        axios.post('http://mathwithpack.com/ZNytvCmJmathwithpackFetchTable.php',
        [{"password":"mreN82Esk276634nGhaksdTvksn88727492329828281"}, {"table":this.props.nonUnitTitlesTB}])
        .then(res => {
          let returnedNonUnitTitles = [];
          for(let i=0;i<res.data.length;i++){
            returnedNonUnitTitles[i] = res.data[i];
          }

          returnedNonUnitTitles.sort((a,b) => a.sectionID - b.sectionID);

          this.setState({ nonUnitTitles: returnedNonUnitTitles });
        });

        axios.post('http://mathwithpack.com/ZNytvCmJmathwithpackFetchTable.php',
        [{"password":"mreN82Esk276634nGhaksdTvksn88727492329828281"}, {"table":this.props.unitsTB}])
        .then(res => {
          let returnedUnitTitles = [];
          for(let i=0;i<res.data.length;i++){
            returnedUnitTitles[i] = res.data[i];
          }

          returnedUnitTitles.sort((a,b) => a.unitNumber - b.unitNumber);
          this.setState({ unitTitles: returnedUnitTitles });
        });

        axios.post('http://mathwithpack.com/ZNytvCmJmathwithpackFetchTable.php',
        [{"password":"mreN82Esk276634nGhaksdTvksn88727492329828281"}, {"table":this.props.announcementsTB}])
        .then(res => {
          let returnedAnnouncements = [];
          for(let i=0;i<res.data.length;i++){
            returnedAnnouncements[i] = res.data[i];
          }

          this.setState({ announcements: returnedAnnouncements });
        });
    }

    render() {
      return (
        <div>
          <div className="has-text-centered">
            <h1 className="title" id="titleTopSpacing">{this.props.pageName}</h1>
          </div>
          {/*
          <section className="section">
            <div className="gCalDiv">
              <iframe src={this.props.gCalLink} 
                className="mathgcal" frameBorder="0" scrolling="no" title={this.props.gCalTitle}></iframe>
            </div>
          </section>
          */}
          <section className="section sectionGray">
            <div className="container">
              <div className="content">
                <h1 className="title has-text-weight-bold">Announcements</h1>
                {this.state.announcements.map(e => {
                  return (
                    <ul className="">
                      <li key={e.ID}>{e.announcement}</li>
                    </ul>
                  );
                })}
              </div>
            </div>
          </section>
          <section className="section">
            <div className="container">
                <div className="content">
                  <div className="nonunitsList">
                    {this.state.nonUnitTitles.map(element => {
                      return (
                        <div key={element.sectionID} className="card">
                          <div className="card-content">
                            <p className="subtitle">{element.title}</p>
                            <div>
                              <ul>
                                {this.state.nonUnitLinks[element.sectionID-1].map(elm => {
                                  return (
                                    <li key={elm.ID}>
                                      <a href={elm.link} target='_blank' rel='noopener noreferrer'>
                                        {elm.linkTitle}
                                      </a>
                                    </li>
                                  ); 
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="unitsList">
                    {this.state.unitTitles.map(element => {
                      return (
                        <div key={element.unitNumber} className="card">
                          <div className="card-content">
                            <p className="subtitle">{element.unitTitle}</p>
                            <div>
                              <ul>
                                {this.state.unitLinks[element.unitNumber-1].map(elm => {
                                  return (
                                    <li key={elm.ID}>
                                      <a href={elm.link} target='_blank' rel='noopener noreferrer'>
                                        {elm.linkTitle}
                                      </a>
                                    </li>
                                  ); 
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  {/*g cal link div - since calendar doesn't show on mobile phones*/}
                  {/*
                  <div className="card">
                    <div className="card-content">
                      <p className="subtitle">Google Calendar Link</p>
                      <div>
                        <ul>
                          <li>
                            <a href={this.props.gCalLink} target='_blank' rel='noopener noreferrer'>
                              {this.props.gCalTitle}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  */}
                </div>
            </div>
          </section>
        </div>
      );
    }
}
import React, { Component } from 'react';
import { NavLink, Route, } from 'react-router-dom';
import Home from './Home.js';
import Math1 from './Math1.js';
import Math2 from './Math2.js';
import Math3 from './Math3.js';
import AFM from './AFM.js';
import '../App.css';
import 'bulma/css/bulma.css';

export default class Dashboard extends Component {
    render() {
      return (
        <div>
          <div class="tabs is-medium is-centered" id="noBottomMargin">
            <ul>
              <li>
                <NavLink exact to="/">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/math1">
                  Math 1
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/math2">
                  Math 2
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/math3">
                  Math 3
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/afm">
                  AFM
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="content">
            <Route exact path="/" component={Home} />
            <Route exact path="/math1" component={Math1} />
            <Route exact path="/math2" component={Math2} />
            <Route exact path="/math3" component={Math3} />
            <Route exact path="/afm" component={AFM} />
          </div>
        </div>
      );
    }
}
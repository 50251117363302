import React, { Component } from "react";
import MathPage from "./MathPage.js";

export default class Math3 extends Component {
  render() {
    return (
      <MathPage
        nonUnitLinksTB="math3NonUnitLinks"
        unitLinksTB="math3UnitLinks"
        nonUnitTitlesTB="math3NonUnitTitles"
        unitsTB="math3Units"
        announcementsTB="math3Announcements"
        pageName="Math 3"
        gCalLink="https://calendar.google.com/calendar/embed?src=daretolearn.org_classroom7d05726a%40group.calendar.google.com&ctz=America%2FNew_York"
        gCalTitle="Math 3 Calendar"
      />
    );
  }
}
